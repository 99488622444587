import "./app.postcss";
import App from "./App.svelte";
import AppStudenti from "./AppStudenti.svelte";
import * as i18n from "./i18n";
import * as Sentry from "@sentry/svelte";

Sentry.init({
    dsn: "https://887a92a6262b49b080f9425f4d85cbf8@o4504916038516736.ingest.sentry.io/4505346135687168",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [/^https:\/\/ezapis\.upce\.cz/,/^https:\/\/ezapis-demo\.upce\.cz/],

    // Capture Replay for 10% of all sessions,
    // plus 100% of sessions with an error
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
});



// console.log(window.location.href)

let app;

if (window.location.href.indexOf("/studenti/app")!==-1) {
    app = new AppStudenti({
        target: document.getElementById("app"),
    });
}
else {
    app = new App({
        target: document.getElementById("app"),
    });
}


export default app;
