import {register, init, getLocaleFromNavigator, getLocaleFromQueryString,locale} from 'svelte-i18n';

import cs from './cs.json';
import en from './en.json';

register('cs', () => import('./cs.json'));
register('en', () => import('./en.json'));

const fallbackLocale = 'cs';
const lngs = [fallbackLocale, 'en'];

locale.subscribe((lng) => {
    if (lng) localStorage.setItem('svelte-i18n-locale', lng);
});

let initialLocale;
const detectedLocale =getLocaleFromQueryString('lang')|| localStorage.getItem('svelte-i18n-locale')  || getLocaleFromNavigator(); // the locale could be region specific, i.e. de-CH
if (lngs.indexOf(detectedLocale) > -1) initialLocale = detectedLocale;
if (!initialLocale && detectedLocale.indexOf('-') > 0) {
    const foundLng = lngs.find((l) => detectedLocale.indexOf(l + '-') === 0);
    if (foundLng) initialLocale = foundLng;
}
if (!initialLocale) initialLocale = fallbackLocale;

init({
    fallbackLocale,
    initialLocale
});



/*init({
    fallbackLocale: 'cs',
    initialLocale: getLocaleFromQueryString('lang')||getLocaleFromNavigator()
    // initialLocale: 'cs',
});*/
